import {PewComponent} from "../../../assets/raw/js/components/pew-component";

const typeahead = require('TypeAhead');
const Bloodhound = require('BloodHound');

export default class RecipePickerComponent extends PewComponent {
  init() {
    this.registerRecipePickerToggle();
  }

  registerRecipePickerToggle() {

    const $searchInput = this.element.find('.recipe-picker-input');
    const dataSet = this.loadDataSet();
    const $typeAheadInstance = $searchInput.typeahead({
      hint: true,
      highlight: true,
      minLength: 1
    }, dataSet);
    $typeAheadInstance.bind('typeahead:select', (ev, suggestion) => {
      this.redirectToRecipe(suggestion.slug);
    });

  }

  loadDataSet() {
    const recipesData = $.parseJSON(this.element.find('.recipes-data').html());

    const recipesEngine = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      local: recipesData
    });
    let dataSetParams = {
      name: 'recipes',
      source: recipesEngine,
      display: "title"
    };

    const notFound = this.element.find('.not-found-template').html();
    if (notFound) {
      dataSetParams.templates = {
        notFound: function(context) {
          return notFound
          .replace('{{query}}', context.query)
          .replace('{{query_url_encoded}}', encodeURIComponent(context.query));
        }
      }
    }

    console.log('dataSetParams', dataSetParams);

    return dataSetParams;
  }

  redirectToRecipe(slug) {
    const loader = '<span class="loader"></span>';
    this.element.append(loader);
    //console.log('redirect to ', slug);
    window.location.href = '/recette/' + slug;
  }

}

window.pew.addRegistryEntry({key: "recipe-picker", domSelector: ".recipe-picker-component", classDef: RecipePickerComponent});
