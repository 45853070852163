import ReadmoreToggle from "../ReadmoreToggle/ReadmoreToggle";
import {gsap} from "gsap";

const {ScrollTrigger} = require("gsapScrollTrigger");
gsap.registerPlugin(ScrollTrigger);

export default class AudioToggle extends ReadmoreToggle {

  open($link, $desc, cb) {
    cb = function ($link, $desc) {
      let $audio = $desc.find('audio');
      if ($audio.length) {
        $audio[0].play();
      }
    };
    this.stopOtherPotentiallyPlayingAudio();
    this.createStopOnScrollListener($link, $desc);
    super.open($link, $desc, cb);
  }

  close($link, $desc, cb) {
    cb = function ($link, $desc) {
      let $audio = $desc.find('audio');
      if ($audio.length) {
        $audio[0].pause();
      }
    };
    this.destroyStopOnScrollListener();
    super.close($link, $desc, cb);
  }

  stopOtherPotentiallyPlayingAudio() {
    // List of all audio tags
    var audios = document.querySelectorAll('audio')
    //Iterate over all
    for (var i = 0; i < audios.length; i++) {
      var audio = audios[i];
      // is playing ?
      if (!audio.paused) {
        //Close audio
        $(audio).parents('.readmore-audio-context').find('.readmore-toggle').click();
      }
    }
  }

  createStopOnScrollListener($link, $desc) {
    this.scrollTrigger = ScrollTrigger.create({
      trigger: $desc,
      //markers: true,
      onLeave: () => {
        this.close($link, $desc);
      },
      onLeaveBack:()=>{
        this.close($link, $desc);
      }
    });
  }

  destroyStopOnScrollListener() {
    if (this.scrollTrigger) {
      this.scrollTrigger.kill();
      this.scrollTrigger = false;
    }
  }

}
window.pew.addRegistryEntry({key: 'audio-toggle', domSelector: '.readmore-audio-context', classDef: AudioToggle});

